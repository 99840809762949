import adminRoutes from "innovate/adminRoutes";
import withApprovalProvider from "innovate/utils/withApprovalProvider";
import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import withInExApprovalProvider from "./utils/withInExApprovalProvider";

const Homepage = lazy(() => import("innovate/pages/Homepage/Homepage"));
const Request = lazy(() => import("innovate/pages/RequestPage/RequestPage"));
const PreLaunchPage = lazy(
  () => import("innovate/pages/Pre-launch/PreLaunchPage")
);
const ComparePage = lazy(
  () => import("innovate/pages/ComparePage/ComparePage")
);
const ResultPage = lazy(() => import("innovate/pages/ResultPage/ResultPage"));
const ErrorPage = lazy(() => import("innovate/pages/Unauthorized/ErrorPage"));
const InnoExplore = lazy(
  () => import("innovate/pages/InnoExplore/InnoExplore")
);
const GlobalView = lazy(() => import("innovate/pages/InnoExplore/GlobalView"));

const router = createBrowserRouter([
  {
    path: "/",
    children: [
      {
        index: true,
        Component: Homepage,
      },
      {
        path: "inno-explore",
        children: [
          {
            index: true,
            Component: withInExApprovalProvider(<InnoExplore />),
          },
          {
            path: "global-view",
            Component: withInExApprovalProvider(<GlobalView />),
          },
        ],
      },
      {
        path: "pre-launch",
        children: [
          {
            index: true,
            Component: withApprovalProvider(<PreLaunchPage />),
          },
          {
            path: "compare",
            Component: withApprovalProvider(<ComparePage />),
          },
          {
            path: "new-simulation",
            Component: withApprovalProvider(<ResultPage />),
          },
          {
            path: "edit-simulation",
            Component: withApprovalProvider(<ResultPage />),
          },
        ],
      },
      {
        path: "/404",
        Component: ErrorPage,
      },
      {
        path: "access",
        children: [
          {
            index: true,
            Component: withApprovalProvider(<Request />),
          },
        ],
      },
    ],
  },
  ...adminRoutes,
]);

export default router;
