import { useAuthenticatedQuery } from "innovate/api/api";
import Loader from "innovate/components/common/Loader";
import { fixed } from "innovate/utils/Constants";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface ApprovalStatusItem {
  name: string;
  status: string;
}
interface ApiResponse {
  approval_status_array: ApprovalStatusItem[];
  user: {
    username: string;
    email: string;
    role: string;
  };
  length: number;
  approval_status: string;
  success: boolean;
}
export const InnoExApprovalContext = createContext<{
  isApproved: boolean;
  isIEApproved: boolean;
  isUser: boolean | null;
  userName: string;
  userType: string;
  setUserName: React.Dispatch<React.SetStateAction<string>>;
  setIsUser: React.Dispatch<React.SetStateAction<boolean | null>>;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}>({
  isApproved: false,
  isIEApproved: false,
  isUser: null,
  userName: "",
  userType: "",
  setUserName: () => {},
  setIsUser: () => {},
  setIsApproved: () => {},
  isLoading: true
});

interface ApprovalProviderProps {
  children: ReactNode;
}

const InnoExploreProvider: React.FC<ApprovalProviderProps> = ({ children }) => {
  const { data, error, isLoading } = useAuthenticatedQuery<ApiResponse>(
    ["approvalStatus", "approvalStatusData"],
    {
      url: `${fixed}approvals/status`,
      method: "GET",
      headers: { "Content-Type": "application/json" },
    }
  );

  const navigate = useNavigate();
  const location = useLocation();
  const [isUser, setIsUser] = useState<boolean | null>(null);
  const [userName, setUserName] = useState<string>("");
  const [userType, setUserType] = useState<string>("");

  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [isIEApproved, setIsIEApproved] = useState<boolean>(false);
  const [apiError, setApiError] = useState<string>("");

  useEffect(() => {
    if (!data?.success) return;
    if (!data?.data) {
      navigate("/access");
      return;
    }
    if (data?.data?.length === 0) {
      navigate("/access");
      return;
    }
    setIsUser(true);
    setUserType(data.data.user.role);
    setUserName(data.data.user.username);
    const approvalStatus = data.data.approval_status === "1";
    // Set approval status for Innobrew and Innoexplore
    const approvalArray = data.data.approval_status_array || [];

    const innoexploreStatus =
      approvalArray.find((item) => item.name == "Innoexplore")?.status === "1";

    setIsIEApproved(innoexploreStatus);
    setIsApproved(approvalStatus);

    if (!approvalStatus || !innoexploreStatus) {
      navigate("/access");
    }
  }, [data, location.pathname, navigate]);

  const value = useMemo(
    () => ({
      isApproved,
      setIsApproved,
      isLoading,
      isUser,
      setIsUser,
      userName,
      setUserName,
      apiError,
      setApiError,
      userType,
      isIEApproved,
    }),
    [
      isApproved,
      isLoading,
      isUser,
      userName,
      apiError,
      userType,
      isIEApproved,
    ]
  );

  if (isLoading) return <Loader dH="90vh" dW="20vw" />;
  if (error) return `Error: ${error.message}`;
  if (data && !data.success) return <Loader dH="90vh" dW="20vw" />;

  return (
    <InnoExApprovalContext.Provider value={value}>
      {children}
    </InnoExApprovalContext.Provider>
  );
};

export default InnoExploreProvider;
